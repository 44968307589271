var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"schedule-view mt-10"},[_c('v-form',{staticClass:"px-5",on:{"submit":function($event){$event.preventDefault();return _vm.update.apply(null, arguments)}},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"mt-4",attrs:{"cols":"12","sm":"2","align-self":"center"}},[_c('span',{staticStyle:{"color":"black","font-size":"16px"}},[_vm._v(_vm._s(_vm.regionFullText)+" DD")])]),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"10"}},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"3"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("SEND TIME")]),_c('v-text-field',{staticClass:"mt-2",attrs:{"rows":"2","rules":[_vm.rules.required],"dense":"","solo":"","type":"time"},model:{value:(_vm.time_dd),callback:function ($$v) {_vm.time_dd=$$v},expression:"time_dd"}})],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"3"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("RESEND 1")]),_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"mt-2",attrs:{"dense":"","solo":"","type":"number","placeholder":"1","suffix":"Days","min":"1","max":"99","rules":[
                    _vm.rules.required,
                    _vm.rules.min(0, _vm.interval_dd1),
                    _vm.rules.max(_vm.interval_dd1),
                  ]},model:{value:(_vm.interval_dd1),callback:function ($$v) {_vm.interval_dd1=$$v},expression:"interval_dd1"}})],1)]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"3"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("RESEND 2")]),_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"mt-2",attrs:{"dense":"","solo":"","type":"number","placeholder":"1","min":_vm.interval_dd1 + 1,"max":"99","suffix":"Days","rules":[
                    _vm.rules.required,
                    _vm.rules.min(_vm.interval_dd1, _vm.interval_dd2),
                    _vm.rules.max(_vm.interval_dd2),
                  ]},model:{value:(_vm.interval_dd2),callback:function ($$v) {_vm.interval_dd2=$$v},expression:"interval_dd2"}})],1)]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"3"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("RESEND 3")]),_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"mt-2",attrs:{"dense":"","solo":"","type":"number","placeholder":"1","suffix":"Days","max":"99","min":_vm.interval_dd2 + 1,"rules":[
                    _vm.rules.required,
                    _vm.rules.min(_vm.interval_dd2, _vm.interval_dd3),
                    _vm.rules.max(_vm.interval_dd3),
                  ]},model:{value:(_vm.interval_dd3),callback:function ($$v) {_vm.interval_dd3=$$v},expression:"interval_dd3"}})],1)])],1)],1)],1),_c('v-row',{staticClass:"mt-10 mb-10"},[_c('v-col',{staticClass:"mt-4",attrs:{"cols":"12","sm":"2","align-self":"center"}},[_c('span',{staticStyle:{"color":"black","font-size":"16px"}},[_vm._v(_vm._s(_vm.regionFullText)+" AL")])]),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"10"}},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"3"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("SEND TIME")]),_c('v-text-field',{staticClass:"mt-2",attrs:{"rows":"2","rules":[_vm.rules.required],"dense":"","solo":"","type":"time"},model:{value:(_vm.time_al),callback:function ($$v) {_vm.time_al=$$v},expression:"time_al"}})],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"3"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("RESEND 1")]),_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"mt-2",attrs:{"dense":"","solo":"","type":"number","max":"99","placeholder":"1","min":"1","suffix":"Days","rules":[
                    _vm.rules.required,
                    _vm.rules.min(0, _vm.interval_al1),
                    _vm.rules.max(_vm.interval_al1),
                  ]},model:{value:(_vm.interval_al1),callback:function ($$v) {_vm.interval_al1=$$v},expression:"interval_al1"}})],1)]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"3"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("RESEND 2")]),_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"mt-2",attrs:{"dense":"","solo":"","type":"number","placeholder":"1","min":"1","suffix":"Days","max":"99","rules":[
                    _vm.rules.required,
                    _vm.rules.min(_vm.interval_al1, _vm.interval_al2),
                    _vm.rules.max(_vm.interval_al2),
                  ]},model:{value:(_vm.interval_al2),callback:function ($$v) {_vm.interval_al2=$$v},expression:"interval_al2"}})],1)]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"3"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("RESEND 3")]),_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"mt-2",attrs:{"dense":"","solo":"","type":"number","placeholder":"1","suffix":"Days","min":_vm.interval_al2 + 1,"max":"99","rules":[
                    _vm.rules.required,
                    _vm.rules.min(_vm.interval_al2, _vm.interval_al3),
                    _vm.rules.max(_vm.interval_al3),
                  ]},model:{value:(_vm.interval_al3),callback:function ($$v) {_vm.interval_al3=$$v},expression:"interval_al3"}})],1)])],1)],1)],1),_c('div',{staticClass:"d-flex text-center justify-center"},[_c('v-btn',{staticClass:"primary",attrs:{"disabled":!_vm.validForm,"loading":_vm.isLoading,"type":"submit"},on:{"click":_vm.update}},[_vm._v("Save")])],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":3000,"value":_vm.notification,"absolute":"","right":"","top":"","tile":"","color":_vm.notificationColor}},[_vm._v(" "+_vm._s(_vm.notificationTxt)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }