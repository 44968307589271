<template>
  <div class="schedule-view mt-10">
    <v-form @submit.prevent="update" v-model="validForm" class="px-5">
      <v-container>
        <v-row>
          <v-col cols="12" sm="2" class="mt-4" align-self="center">
            <span style="color: black; font-size: 16px"
              >{{ regionFullText }} DD</span
            >
          </v-col>
          <v-col cols="12" sm="10" class="d-flex">
            <v-row>
              <v-col cols="12" sm="3" class="text-center">
                <span class="font-weight-bold">SEND TIME</span>
                <v-text-field
                  rows="2"
                  :rules="[rules.required]"
                  dense
                  solo
                  class="mt-2"
                  type="time"
                  v-model="time_dd"
                />
              </v-col>
              <v-col cols="12" sm="3" class="text-center">
                <span class="font-weight-bold">RESEND 1</span>
                <div class="d-flex">
                  <v-text-field
                    dense
                    class="mt-2"
                    solo
                    type="number"
                    v-model="interval_dd1"
                    placeholder="1"
                    suffix="Days"
                    min="1"
                    max="99"
                    :rules="[
                      rules.required,
                      rules.min(0, interval_dd1),
                      rules.max(interval_dd1),
                    ]"
                  />
                </div>
              </v-col>
              <v-col cols="12" sm="3" class="text-center">
                <span class="font-weight-bold">RESEND 2</span>
                <div class="d-flex">
                  <v-text-field
                    dense
                    solo
                    class="mt-2"
                    type="number"
                    v-model="interval_dd2"
                    placeholder="1"
                    :min="interval_dd1 + 1"
                    max="99"
                    suffix="Days"
                    :rules="[
                      rules.required,
                      rules.min(interval_dd1, interval_dd2),
                      rules.max(interval_dd2),
                    ]"
                  />
                </div>
              </v-col>
              <v-col cols="12" sm="3" class="text-center">
                <span class="font-weight-bold">RESEND 3</span>
                <div class="d-flex">
                  <v-text-field
                    dense
                    solo
                    class="mt-2"
                    type="number"
                    v-model="interval_dd3"
                    placeholder="1"
                    suffix="Days"
                    max="99"
                    :min="interval_dd2 + 1"
                    :rules="[
                      rules.required,
                      rules.min(interval_dd2, interval_dd3),
                      rules.max(interval_dd3),
                    ]"
                  />
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mt-10 mb-10">
          <v-col cols="12" sm="2" class="mt-4" align-self="center">
            <span style="color: black; font-size: 16px"
              >{{ regionFullText }} AL</span
            >
          </v-col>
          <v-col cols="12" sm="10" class="d-flex">
            <v-row>
              <v-col cols="12" sm="3" class="text-center">
                <span class="font-weight-bold">SEND TIME</span>
                <v-text-field
                  rows="2"
                  :rules="[rules.required]"
                  dense
                  solo
                  class="mt-2"
                  type="time"
                  v-model="time_al"
                />
              </v-col>
              <v-col cols="12" sm="3" class="text-center">
                <span class="font-weight-bold">RESEND 1</span>
                <div class="d-flex">
                  <v-text-field
                    dense
                    class="mt-2"
                    solo
                    type="number"
                    max="99"
                    v-model="interval_al1"
                    placeholder="1"
                    min="1"
                    suffix="Days"
                    :rules="[
                      rules.required,
                      rules.min(0, interval_al1),
                      rules.max(interval_al1),
                    ]"
                  />
                </div>
              </v-col>
              <v-col cols="12" sm="3" class="text-center">
                <span class="font-weight-bold">RESEND 2</span>
                <div class="d-flex">
                  <v-text-field
                    dense
                    solo
                    class="mt-2"
                    type="number"
                    v-model="interval_al2"
                    placeholder="1"
                    min="1"
                    suffix="Days"
                    max="99"
                    :rules="[
                      rules.required,
                      rules.min(interval_al1, interval_al2),
                      rules.max(interval_al2),
                    ]"
                  />
                </div>
              </v-col>
              <v-col cols="12" sm="3" class="text-center">
                <span class="font-weight-bold">RESEND 3</span>
                <div class="d-flex">
                  <v-text-field
                    dense
                    solo
                    class="mt-2"
                    type="number"
                    v-model="interval_al3"
                    placeholder="1"
                    suffix="Days"
                    :min="interval_al2 + 1"
                    max="99"
                    :rules="[
                      rules.required,
                      rules.min(interval_al2, interval_al3),
                      rules.max(interval_al3),
                    ]"
                  />
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <div class="d-flex text-center justify-center">
          <v-btn
            class="primary"
            :disabled="!validForm"
            :loading="isLoading"
            type="submit"
            @click="update"
            >Save</v-btn
          >
        </div>
      </v-container>
    </v-form>
    <v-snackbar
      :timeout="3000"
      :value="notification"
      absolute
      right
      top
      tile
      :color="notificationColor"
    >
      {{ notificationTxt }}
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  APIdoUpdateSMSSchedulesSetting,
  APIdoSmsGenerate,
} from "@/api/common/api";

export default {
  props: {
    region: {
      type: String,
      required: true,
    },
    regionFullText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      time_dd: "",
      interval_dd1: 0,
      interval_dd2: 0,
      interval_dd3: 0,
      time_al: "",
      interval_al1: 0,
      interval_al2: 0,
      interval_al3: 0,
      notification: false,
      notificationTxt: "",
      notificationColor: "",
      isLoading: false,
      generateLoading: false,
      validForm: false,
      rules: {
        min(min, v) {
          return (
            parseInt(v) > parseInt(min) || `It must be greater than ${min}`
          );
        },
        max(v) {
          return parseInt(v) < 100 || `The max value is 99.`;
        },
        required: (value) => !!value || "Required.",
      },
    };
  },
  mounted() {
    const time_dd_data = this.schedulesValue["time_dd_" + this.region];
    const interval_dd1_data =
      this.schedulesValue["interval_dd1_" + this.region];
    const interval_dd2_data =
      this.schedulesValue["interval_dd2_" + this.region];
    const interval_dd3_data =
      this.schedulesValue["interval_dd3_" + this.region];
    const time_al_data = this.schedulesValue["time_al_" + this.region];
    const interval_al1_data =
      this.schedulesValue["interval_al1_" + this.region];
    const interval_al2_data =
      this.schedulesValue["interval_al2_" + this.region];
    const interval_al3_data =
      this.schedulesValue["interval_al3_" + this.region];

    this.time_dd = time_dd_data;
    this.interval_dd1 = interval_dd1_data ? parseInt(interval_dd1_data) : 0;
    this.interval_dd2 = interval_dd2_data ? parseInt(interval_dd2_data) : 0;
    this.interval_dd3 = interval_dd3_data ? parseInt(interval_dd3_data) : 0;

    this.time_al = time_al_data;
    this.interval_al1 = interval_al1_data ? parseInt(interval_al1_data) : 0;
    this.interval_al2 = interval_al2_data ? parseInt(interval_al2_data) : 0;
    this.interval_al3 = interval_al3_data ? parseInt(interval_al3_data) : 0;
  },
  computed: {
    ...mapGetters({
      schedulesValue: "getSchedulesValue",
    }),
  },
  methods: {
    ...mapActions({
      doGetSchedules: "doGetSchedules",
    }),
    update() {
      if (this.validForm) {
        const postData = {
          time_dd: this.time_dd,
          interval_dd1: this.interval_dd1,
          interval_dd2: this.interval_dd2,
          interval_dd3: this.interval_dd3,
          time_al: this.time_al,
          interval_al1: this.interval_al1,
          interval_al2: this.interval_al2,
          interval_al3: this.interval_al3,
        };
        this.isLoading = true;
        APIdoUpdateSMSSchedulesSetting(postData, this.region)
          .then((res) => {
            this.notification = true;
            this.notificationTxt = "Successfully Updated";
            this.notificationColor = "success";
            this.doGetSchedules();
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response.data.status === "unauthorized") {
              localStorage.removeItem("user");
              this.$router.push("/");
            }
          });
      }
    },
  },
};
</script>
<style lang="scss">
// .schedule-view {
//   .v-text-field__details {
//     display: none;
//   }
// }
</style>
