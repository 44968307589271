<template>
  <v-container id="regular-tables" fluid tag="section" class="pt-10">
    <v-tabs v-model="tabs" fixed-tabs>
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#mobile-tabs-5-1" class="primary--text">
        <v-text>London</v-text>
      </v-tab>

      <v-tab href="#mobile-tabs-5-2" class="primary--text">
        <v-text>Scotland</v-text>
      </v-tab>

      <v-tab href="#mobile-tabs-5-3" class="primary--text">
        <v-text>Midlands</v-text>
      </v-tab>
      <v-tab href="#mobile-tabs-5-4" class="primary--text">
        <v-text>Southern</v-text>
      </v-tab>
      <v-tab href="#mobile-tabs-5-5" class="primary--text">
        <v-text>Wales & South West</v-text>
      </v-tab>
      <v-tab href="#mobile-tabs-5-6" class="primary--text">
        <v-text>Northern</v-text>
      </v-tab>
      <v-tab href="#mobile-tabs-5-7" class="primary--text">
        <v-text>North West</v-text>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabs">
      <v-tab-item value="mobile-tabs-5-1">
        <ScheduleView region="lo" regionFullText="London" />
      </v-tab-item>
      <v-tab-item value="mobile-tabs-5-2">
        <ScheduleView region="sc" regionFullText="Scotland" />
      </v-tab-item>
      <v-tab-item value="mobile-tabs-5-3">
        <ScheduleView region="mi" regionFullText="Midlands" />
      </v-tab-item>
      <v-tab-item value="mobile-tabs-5-4">
        <ScheduleView region="so" regionFullText="Southern" />
      </v-tab-item>
      <v-tab-item value="mobile-tabs-5-5">
        <ScheduleView region="sw" regionFullText="Wales & South West" />
      </v-tab-item>
      <v-tab-item value="mobile-tabs-5-6">
        <ScheduleView region="no" regionFullText="Northern" />
      </v-tab-item>
      <v-tab-item value="mobile-tabs-5-7">
        <ScheduleView region="nw" regionFullText="North West" />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import ScheduleView from "../component/Schedule/";

import { mapGetters } from "vuex";

export default {
  data() {
    return {
      tabs: null,
    };
  },
  components: {
    ScheduleView,
  },
  mounted() {
    if (this.getUserDetails.user_type !== "admin") this.$router.push("/");
  },
  computed: {
    ...mapGetters({
      getUserDetails: "getUserData",
    }),
  },
};
</script>
